export const appHead = {"link":[],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"og:image","property":"og:image","content":"https://framerusercontent.com/images/78brByyqyUHwpV1QKICfh72CQk.png"},{"hid":"og:image:width","property":"og:image:width","content":"1200"},{"hid":"og:image:height","property":"og:image:height","content":"630"},{"hid":"twitter:image","property":"twitter:image","content":"https://framerusercontent.com/images/78brByyqyUHwpV1QKICfh72CQk.png"},{"hid":"twitter:image:height","property":"twitter:image:height","content":"630"},{"hid":"twitter:image:width","property":"twitter:image:width","content":"1200"},{"hid":"og:site_name","property":"og:site_name","content":"StreamersClub"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"hid":"twitter:site","property":"twitter:site","content":"@streamersclub"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"