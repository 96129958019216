import { toast } from 'vue-sonner'

export default defineNuxtPlugin(nuxtApp => {
	const utils = {
		copyToClipboard: (text: string) => {
			if (!navigator.clipboard) {
				toast.error('Seu navegador não suporta a funcionalidade de copiar para a área de transferência')
				return
			}

			navigator.clipboard
				.writeText(text)
				.then(() => {
					toast.success('Copiado para a área de transferência')
				})
				.catch(() => {
					toast.error('Erro ao copiar para a área de transferência')
				})
		},
	}

	return {
		provide: {
			utils,
		},
	}
})
