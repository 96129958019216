<template>
	<svg width="129" height="27" viewBox="0 0 129 27" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.135742 7.11367C0.208016 7.10393 0.28029 7.08447 0.352564 7.08447C4.78276 7.08252 9.21492 7.08252 13.6451 7.08252C13.7115 7.08252 13.7779 7.08252 13.858 7.08252C13.858 8.00538 13.858 8.91072 13.858 9.83553C10.2053 9.83553 6.56031 9.83553 2.90364 9.83553C2.90364 10.7545 2.90364 11.6521 2.90364 12.571C6.55836 12.571 10.2033 12.571 13.86 12.571C13.86 15.3065 13.86 18.0225 13.86 20.7619C13.7994 20.7658 13.735 20.7736 13.6705 20.7736C9.22663 20.7736 4.78472 20.7736 0.340844 20.7736C0.272477 20.7736 0.206065 20.7619 0.137697 20.7561C0.137697 19.8546 0.137697 18.9512 0.137697 18.0498C0.221691 18.04 0.307639 18.0206 0.391634 18.0206C3.89008 18.0186 7.38658 18.0186 10.885 18.0186C10.9632 18.0186 11.0432 18.0186 11.1253 18.0186C11.1253 17.0977 11.1253 16.2021 11.1253 15.289C11.0237 15.289 10.9378 15.289 10.8518 15.289C7.3729 15.289 3.89399 15.289 0.413117 15.289C0.32131 15.289 0.229503 15.2773 0.135742 15.2715C0.135742 12.5515 0.135742 9.83358 0.135742 7.11367Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M69.7646 26.6122C69.7646 25.3155 69.7646 24.0208 69.7646 22.7124C70.0284 22.7124 70.2803 22.7124 70.5479 22.7124C70.5479 23.7365 70.5479 24.7548 70.5479 25.7867C70.8136 25.7867 71.0617 25.7867 71.3273 25.7867C71.3273 24.7684 71.3273 23.7521 71.3273 22.7182C71.593 22.7182 71.8411 22.7182 72.1145 22.7182C72.1145 23.7404 72.1145 24.7606 72.1145 25.7925C72.3821 25.7925 72.6263 25.7925 72.89 25.7925C72.89 24.7645 72.89 23.7423 72.89 22.7124C73.1615 22.7124 73.4116 22.7124 73.6733 22.7124C73.6733 24.0208 73.6733 25.3155 73.6733 26.6122C72.3724 26.6122 71.0675 26.6122 69.7646 26.6122Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M63.2266 7.09204C67.7954 7.09204 72.3468 7.09204 76.9156 7.09204C76.9156 7.99738 76.9156 8.90078 76.9156 9.83337C73.2707 9.83337 69.6218 9.83337 65.9593 9.83337C65.9593 10.7543 65.9593 11.6479 65.9593 12.5591C69.6062 12.5591 73.2531 12.5591 76.9156 12.5591C76.9156 13.4703 76.9156 14.362 76.9156 15.281C73.2707 15.281 69.6218 15.281 65.9593 15.281C65.9593 16.2019 65.9593 17.0956 65.9593 18.0067C69.6062 18.0067 73.2531 18.0067 76.9156 18.0067C76.9156 18.9296 76.9156 19.833 76.9156 20.7539C72.3585 20.7539 67.7974 20.7539 63.2266 20.7539C63.2266 16.2058 63.2266 11.6616 63.2266 7.09204Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M79.0044 7.09204C83.5674 7.09204 88.1187 7.09204 92.6876 7.09204C92.6876 7.99738 92.6876 8.90077 92.6876 9.82947C89.0427 9.82947 85.3938 9.82947 81.7313 9.82947C81.7313 10.7504 81.7313 11.6499 81.7313 12.563C85.3801 12.563 89.027 12.563 92.6876 12.563C92.6876 15.3043 92.6876 18.0301 92.6876 20.7636C88.1285 20.7636 83.5694 20.7636 79.0063 20.7636C79.0063 19.8486 79.0063 18.9432 79.0063 18.0204C82.6572 18.0204 86.2962 18.0204 89.951 18.0204C89.951 17.1072 89.951 16.2097 89.951 15.2888C86.3002 15.2888 82.6552 15.2888 79.0063 15.2888C79.0044 12.5455 79.0044 9.82363 79.0044 7.09204Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M45.3971 7.09424C45.3971 11.6501 45.3971 16.1944 45.3971 20.7561C44.4907 20.7561 43.5844 20.7561 42.6604 20.7561C42.6604 18.9337 42.6604 17.1211 42.6604 15.2988C39.9101 15.2988 37.1774 15.2988 34.4251 15.2988C34.4251 17.1231 34.4251 18.9357 34.4251 20.7619C33.5051 20.7619 32.6026 20.7619 31.6885 20.7619C31.6885 16.2041 31.6885 11.656 31.6885 7.09424C36.2593 7.09424 40.8165 7.09424 45.3971 7.09424ZM34.431 12.5574C37.1832 12.5574 39.916 12.5574 42.6507 12.5574C42.6507 11.6482 42.6507 10.7487 42.6507 9.85309C39.9043 9.85309 37.1715 9.85309 34.431 9.85309C34.431 10.7604 34.431 11.6521 34.431 12.5574Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M129.012 15.3183C125.367 15.3183 121.714 15.3183 118.048 15.3183C118.048 17.1367 118.048 18.9396 118.048 20.7581C117.118 20.7581 116.206 20.7581 115.284 20.7581C115.284 16.2139 115.284 11.6775 115.284 7.11182C119.856 7.11182 124.418 7.11182 129.014 7.11182C129.012 9.82589 129.012 12.5555 129.012 15.3183ZM118.054 9.8512C118.054 10.7604 118.054 11.656 118.054 12.5575C120.792 12.5575 123.517 12.5575 126.242 12.5575C126.242 11.6463 126.242 10.7488 126.242 9.8512C123.507 9.8512 120.786 9.8512 118.054 9.8512Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M29.6363 15.3122C25.9855 15.3122 22.3328 15.3122 18.6585 15.3122C18.6585 17.1307 18.6585 18.9375 18.6585 20.7598C17.7287 20.7598 16.8224 20.7598 15.9043 20.7598C15.9043 16.2195 15.9043 11.6831 15.9043 7.11548C20.4771 7.11548 25.044 7.11548 29.6363 7.11548C29.6363 9.8315 29.6363 12.5573 29.6363 15.3122ZM26.8626 9.85291C24.1201 9.85291 21.3932 9.85291 18.6624 9.85291C18.6624 10.7641 18.6624 11.6616 18.6624 12.5592C21.403 12.5592 24.1299 12.5592 26.8626 12.5592C26.8626 11.6539 26.8626 10.7602 26.8626 9.85291Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M50.1956 9.83751C50.1956 12.5749 50.1956 15.2832 50.1956 18.0109C53.8464 18.0109 57.4914 18.0109 61.15 18.0109C61.15 18.9357 61.15 19.8391 61.15 20.7581C56.5968 20.7581 52.0396 20.7581 47.4688 20.7581C47.4688 16.208 47.4688 11.6599 47.4688 7.09619C52.022 7.09619 56.5792 7.09619 61.15 7.09619C61.15 7.99958 61.15 8.90298 61.15 9.83557C57.5031 9.83752 53.8543 9.83751 50.1956 9.83751Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M94.7598 7.09204C95.6661 7.09204 96.5647 7.09204 97.4788 7.09204C97.4788 8.91051 97.4788 10.7251 97.4788 12.5533C100.235 12.5533 102.974 12.5533 105.728 12.5533C105.728 10.7387 105.728 8.92414 105.728 7.09594C106.638 7.09594 107.535 7.09594 108.445 7.09594C108.445 11.646 108.445 16.1941 108.445 20.7578C107.55 20.7578 106.654 20.7578 105.742 20.7578C105.742 18.9432 105.742 17.1306 105.742 15.3024C102.987 15.3024 100.251 15.3024 97.4925 15.3024C97.4925 17.1209 97.4925 18.9335 97.4925 20.7597C96.5686 20.7597 95.6681 20.7597 94.7617 20.7597C94.7598 16.2019 94.7598 11.6518 94.7598 7.09204Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M113.325 26.5478C112.371 26.5478 111.443 26.5478 110.506 26.5478C110.506 20.0625 110.506 13.5869 110.506 7.08986C110.568 7.08597 110.629 7.08013 110.688 7.08013C111.494 7.08013 112.301 7.08402 113.108 7.07623C113.274 7.07429 113.33 7.11712 113.33 7.29429C113.327 13.6492 113.326 20.006 113.326 26.3609C113.325 26.4155 113.325 26.47 113.325 26.5478Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M109.523 2.91006C109.385 1.45373 110.721 0.571753 111.938 0.665207C113.041 0.748927 114.104 1.58807 113.975 2.90033C114.231 2.95874 114.493 2.99962 114.744 3.0775C115.051 3.1729 115.35 3.29167 115.647 3.41238C115.743 3.45132 115.829 3.51751 115.909 3.58566C116.225 3.85044 116.221 4.15028 115.897 4.40533C115.575 4.65844 115.192 4.78694 114.801 4.88818C114.455 4.97774 114.102 5.04394 113.746 5.12182C113.735 5.26979 113.768 5.37102 113.938 5.42164C114.108 5.47227 114.25 5.59103 114.258 5.78962C114.266 5.97848 114.141 6.09724 113.989 6.17902C113.645 6.36009 113.289 6.37761 112.932 6.22186C112.785 6.15761 112.668 6.05636 112.658 5.87919C112.649 5.70201 112.746 5.5774 112.889 5.50731C113.047 5.43138 113.063 5.33793 112.992 5.21916C112.18 5.21916 111.375 5.21916 110.562 5.21916C110.515 5.34572 110.541 5.43917 110.701 5.50148C110.861 5.56573 110.986 5.68254 110.986 5.87529C110.986 6.06999 110.857 6.18097 110.701 6.253C110.344 6.4185 109.978 6.42239 109.629 6.22769C109.293 6.04078 109.289 5.69617 109.635 5.52679C109.83 5.43139 109.855 5.31068 109.81 5.12571C109.496 5.05952 109.17 5.01278 108.857 4.92322C108.519 4.82587 108.187 4.70517 107.867 4.56304C107.623 4.45401 107.376 4.3002 107.376 3.99063C107.376 3.68106 107.632 3.54088 107.867 3.41433C108.379 3.13591 108.945 3.02104 109.523 2.91006Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M83.6084 22.6989C83.6084 23.9975 83.6084 25.2805 83.6084 26.5714C82.3113 26.5714 81.0241 26.5714 79.7212 26.5714C79.7212 26.3222 79.7212 26.0729 79.7212 25.8043C79.8306 25.8043 79.9458 25.8043 80.0728 25.8043C80.0728 25.0235 80.0728 24.2623 80.0728 23.4893C79.9517 23.4835 79.8364 23.4757 79.7173 23.4699C79.7173 23.207 79.7173 22.9578 79.7173 22.6989C81.0163 22.6989 82.3035 22.6989 83.6084 22.6989ZM82.8173 25.0352C82.1648 25.0352 81.5339 25.0352 80.8913 25.0352C80.8913 25.2903 80.8913 25.5336 80.8913 25.7868C81.5359 25.7868 82.1707 25.7868 82.8173 25.7868C82.8173 25.5356 82.8173 25.2922 82.8173 25.0352ZM82.8192 23.4991C82.1707 23.4991 81.532 23.4991 80.8952 23.4991C80.8952 23.7541 80.8952 23.9916 80.8952 24.2331C81.5437 24.2331 82.1785 24.2331 82.8192 24.2331C82.8192 23.9819 82.8192 23.7444 82.8192 23.4991Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M88.5752 22.6989C88.5752 22.9578 88.5752 23.207 88.5752 23.4776C87.5418 23.4776 86.5144 23.4776 85.4732 23.4776C85.4732 23.7405 85.4732 23.9839 85.4732 24.2447C86.5085 24.2447 87.534 24.2447 88.5732 24.2447C88.5732 25.0255 88.5732 25.7887 88.5732 26.5675C87.2801 26.5675 85.9889 26.5675 84.686 26.5675C84.686 26.3124 84.686 26.0632 84.686 25.7965C85.7233 25.7965 86.7507 25.7965 87.7919 25.7965C87.7919 25.5375 87.7919 25.2942 87.7919 25.0313C86.7566 25.0313 85.7291 25.0313 84.6899 25.0313C84.6899 24.2448 84.6899 23.4757 84.6899 22.6989C85.9889 22.6989 87.2762 22.6989 88.5752 22.6989Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M78.6269 25.787C78.6269 26.0654 78.6269 26.3107 78.6269 26.5677C77.3318 26.5677 76.0465 26.5677 74.7476 26.5677C74.7476 25.2808 74.7476 23.9997 74.7476 22.703C76.0387 22.703 77.324 22.703 78.623 22.703C78.623 22.9581 78.623 23.2073 78.623 23.476C77.5955 23.476 76.5739 23.476 75.5367 23.476C75.5367 23.7368 75.5367 23.9783 75.5367 24.2392C76.5583 24.2392 77.5799 24.2392 78.6191 24.2392C78.6191 24.5039 78.6191 24.7532 78.6191 25.0238C77.5936 25.0238 76.5681 25.0238 75.5309 25.0238C75.5309 25.2866 75.5309 25.5261 75.5309 25.787C76.5544 25.787 77.5799 25.787 78.6269 25.787Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M128.41 25.8024C128.41 25.5338 128.41 25.2943 128.41 25.0315C127.373 25.0315 126.341 25.0315 125.3 25.0315C125.3 24.2429 125.3 23.4797 125.3 22.7009C126.591 22.7009 127.883 22.7009 129.187 22.7009C129.187 22.9501 129.187 23.1993 129.187 23.4661C128.154 23.4661 127.127 23.4661 126.086 23.4661C126.086 23.727 126.086 23.9704 126.086 24.2371C127.115 24.2371 128.146 24.2371 129.189 24.2371C129.189 25.0237 129.189 25.7869 129.189 26.5676C127.9 26.5676 126.607 26.5676 125.302 26.5676C125.302 26.3184 125.302 26.0692 125.302 25.8044C126.336 25.8024 127.361 25.8024 128.41 25.8024Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M119.248 22.6973C119.248 23.9901 119.248 25.2712 119.248 26.5678C117.951 26.5678 116.658 26.5678 115.354 26.5678C115.354 25.2848 115.354 23.9978 115.354 22.6973C116.643 22.6973 117.936 22.6973 119.248 22.6973ZM118.461 23.4994C117.68 23.4994 116.916 23.4994 116.154 23.4994C116.154 24.2724 116.154 25.0278 116.154 25.7832C116.934 25.7832 117.698 25.7832 118.461 25.7832C118.461 25.0161 118.461 24.2626 118.461 23.4994Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M93.5547 22.7046C93.5547 23.9974 93.5547 25.2785 93.5547 26.5713C92.2537 26.5713 90.9606 26.5713 89.6577 26.5713C89.6577 25.2824 89.6577 24.0013 89.6577 22.7046C90.9508 22.7046 92.242 22.7046 93.5547 22.7046ZM90.4469 25.7827C91.2263 25.7827 91.9978 25.7827 92.7635 25.7827C92.7635 25.0137 92.7635 24.2563 92.7635 23.5067C91.9842 23.5067 91.2165 23.5067 90.4469 23.5067C90.4469 24.2738 90.4469 25.0254 90.4469 25.7827Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M124.224 22.6952C124.224 23.986 124.224 25.2671 124.224 26.5658C123.972 26.5658 123.722 26.5658 123.445 26.5658C123.445 25.5436 123.445 24.5175 123.445 23.4798C122.657 23.4798 121.898 23.4798 121.112 23.4798C121.112 24.5078 121.112 25.5339 121.112 26.5716C120.843 26.5716 120.597 26.5716 120.339 26.5716C120.339 25.2808 120.339 23.9938 120.339 22.6952C121.63 22.6952 122.923 22.6952 124.224 22.6952Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M103.489 26.5716C102.188 26.5716 100.903 26.5716 99.606 26.5716C99.606 25.2788 99.606 23.9977 99.606 22.703C99.8579 22.703 100.102 22.703 100.374 22.703C100.374 23.7271 100.374 24.7532 100.374 25.789C101.167 25.789 101.932 25.789 102.714 25.789C102.714 24.761 102.714 23.7388 102.714 22.703C102.979 22.703 103.229 22.703 103.489 22.703C103.489 23.9919 103.489 25.273 103.489 26.5716Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M108.472 23.4721C107.949 23.4721 107.443 23.4721 106.911 23.4721C106.911 24.5137 106.911 25.5417 106.911 26.5736C106.64 26.5736 106.394 26.5736 106.128 26.5736C106.128 25.5417 106.128 24.5195 106.128 23.474C105.605 23.474 105.101 23.474 104.587 23.474C104.587 23.2073 104.587 22.9619 104.587 22.703C105.88 22.703 107.171 22.703 108.474 22.703C108.472 22.9542 108.472 23.1975 108.472 23.4721Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M98.5175 25.7925C98.5175 26.0612 98.5175 26.3104 98.5175 26.5713C97.2244 26.5713 95.9372 26.5713 94.6421 26.5713C94.6421 25.2804 94.6421 23.9993 94.6421 22.7046C94.8921 22.7046 95.1421 22.7046 95.4098 22.7046C95.4098 23.7326 95.4098 24.7528 95.4098 25.7925C96.4548 25.7925 97.4803 25.7925 98.5175 25.7925Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M114.672 2.11572C114.715 2.17024 114.778 2.21891 114.797 2.27927C114.832 2.3883 114.746 2.41166 114.658 2.41945C114.535 2.43113 114.412 2.44087 114.289 2.45644C114.186 2.47007 114.123 2.42724 114.143 2.32794C114.152 2.27537 114.211 2.20334 114.262 2.18971C114.381 2.1605 114.506 2.16051 114.629 2.14882C114.643 2.13909 114.658 2.1274 114.672 2.11572Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M114.119 1.87251C114.076 1.81605 114.03 1.77517 114.012 1.7226C114 1.68755 114.018 1.61746 114.047 1.59994C114.182 1.51622 114.323 1.43834 114.467 1.37214C114.498 1.35851 114.573 1.37993 114.588 1.40719C114.618 1.45391 114.641 1.55516 114.623 1.56879C114.467 1.67392 114.301 1.76543 114.119 1.87251Z"
			fill="white"
			fill-opacity="0.4"
		/>
		<path
			d="M114.198 0.965233C114.051 1.09957 113.916 1.22807 113.774 1.34295C113.754 1.35852 113.666 1.32737 113.649 1.29622C113.625 1.25728 113.62 1.17746 113.645 1.14436C113.731 1.03338 113.829 0.928242 113.934 0.832841C113.965 0.803636 114.041 0.803635 114.088 0.819211C114.125 0.834786 114.145 0.891248 114.198 0.965233Z"
			fill="white"
			fill-opacity="0.4"
		/>
	</svg>
</template>
