<script setup lang="ts">
import { Toaster } from 'vue-sonner'

const { user: loggedUser } = useUserStore()
const { $identifyUser } = useNuxtApp()

watch(
	() => loggedUser,
	user => {
		if (user) {
			$identifyUser(user)
		}
	}
)
</script>

<template>
	<div>
		<NuxtRouteAnnouncer />
		<NuxtPage />
		<Footer />
		<Toaster :expand="true" />
	</div>
</template>
