import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as joinXymQ7Qo6rbMeta } from "/usr/src/app/pages/join.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as _91id_93JIYuytAqbOMeta } from "/usr/src/app/pages/orders/success/[id].vue?macro=true";
import { default as _91id_93xw06jWy6cGMeta } from "/usr/src/app/pages/orders/tracking/[id].vue?macro=true";
import { default as dashboarda7ITGA2Y69Meta } from "/usr/src/app/pages/profile/dashboard.vue?macro=true";
import { default as indexkoccyk9js9Meta } from "/usr/src/app/pages/streamers/[slug]/index.vue?macro=true";
import { default as indexm0forJpsiLMeta } from "/usr/src/app/pages/streamers/index.vue?macro=true";
export default [
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/usr/src/app/pages/checkout/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    meta: joinXymQ7Qo6rbMeta || {},
    component: () => import("/usr/src/app/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "orders-success-id",
    path: "/orders/success/:id()",
    component: () => import("/usr/src/app/pages/orders/success/[id].vue")
  },
  {
    name: "orders-tracking-id",
    path: "/orders/tracking/:id()",
    meta: _91id_93xw06jWy6cGMeta || {},
    component: () => import("/usr/src/app/pages/orders/tracking/[id].vue")
  },
  {
    name: "profile-dashboard",
    path: "/profile/dashboard",
    meta: dashboarda7ITGA2Y69Meta || {},
    component: () => import("/usr/src/app/pages/profile/dashboard.vue")
  },
  {
    name: "streamers-slug",
    path: "/streamers/:slug()",
    meta: indexkoccyk9js9Meta || {},
    component: () => import("/usr/src/app/pages/streamers/[slug]/index.vue")
  },
  {
    name: "streamers",
    path: "/streamers",
    component: () => import("/usr/src/app/pages/streamers/index.vue")
  }
]