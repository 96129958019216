export default defineNuxtPlugin(() => {
	const userRequestHeaders = useRequestHeaders(['cookie'])
	const config = useRuntimeConfig()

	const api = $fetch.create({
		baseURL: import.meta.server ? `${config.public.baseUrl}/api` : '/api',
		onRequest({ options }: any) {
			if (import.meta.server && !!userRequestHeaders.cookie) {
				options.headers.set('cookie', userRequestHeaders.cookie)
			}
		},
	})

	return {
		provide: {
			api,
		},
	}
})
