<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
	error: Object as () => NuxtError,
})

const handleError = () => clearError({ redirect: '/' })

const isOpenMenu = ref(false)

useHead({
	title: 'Página não encontrada',
	meta: [
		{
			hid: 'description',
			name: 'description',
			content: 'Página não encontrada',
		},
	],
})
</script>

<template>
	<div class="mx-auto">
		<HeaderMobile @onToggle="value => (isOpenMenu = value)" :is-open="isOpenMenu" />

		<div class="lg:container lg:mt-8 mb-8 lg:mx-auto bg-white rounded-2xl md:px-28 py-10">
			<div class="flex items-center flex-col mx-8 lg:container lg:mx-auto">
				<button @click="handleError" class="hidden md:block">
					<img src="/images/logo-black.svg" alt="logo" class="w-[100px] h-[100px] mb-12" />
				</button>

				<img src="/images/404.png" alt="not found illustration" />

				<p class="text-xl lg:text-2xl text-center mt-8 text-[#151515]">
					Página não encontrada. <br />
					<button @click="handleError" class="text-[#6F9900] underline font-bold">
						Volte para a página inicial.
					</button>
				</p>
			</div>
		</div>
	</div>
</template>
