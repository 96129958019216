<template>
	<div class="lg:hidden my-6 mx-4 flex items-center justify-between md:mx-16" v-if="showControls">
		<NuxtLink to="/">
			<img src="/images/logo.svg" alt="logo" />
		</NuxtLink>
		<button @click="() => $emit('onToggle', true)">
			<Menu class="text-white w-8 h-8" />
		</button>
	</div>
	<div v-if="isOpen" id="modal" class="fixed w-screen h-screen top-0 left-0 bg-[#050607] z-30 pt-6 px-4 z-50">
		<div class="flex items-center justify-between pb-8">
			<NuxtLink to="/">
				<img src="/images/logo.svg" alt="logo" />
			</NuxtLink>

			<button @click="() => $emit('onToggle', false)">
				<X class="text-white w-9 h-9" />
			</button>
		</div>
		<div class="bg-[#21262B] h-px w-full"></div>
		<div class="grid grid-cols-1 text-center">
			<NuxtLink
				:to="item?.href ?? '#'"
				@click="item?.action"
				v-for="item in dynamicItems as any[]"
				class="cursor-pointer mt-8 font-jakarta font-extrabold text-xl text-white"
			>
				{{ item.label }}
			</NuxtLink>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Menu, LogOut, X } from 'lucide-vue-next'

const props = defineProps({
	isOpen: {
		type: Boolean,
		default: false,
	},
	showControls: {
		type: Boolean,
		default: true,
	},
	items: {
		type: Array,
	},
})

defineEmits(['onToggle'])

const dynamicItems = computed(() => {
	return (
		props.items || [
			{
				label: 'Loja',
				href: '/streamers',
			},
			{
				label: 'Como funciona',
				href: '/',
			},
		]
	)
})
</script>
