import posthog, { PostHog } from 'posthog-js'
import type { IUser } from '~/types/models/user.interface'

export default defineNuxtPlugin(nuxtApp => {
	const config = useRuntimeConfig()

	const posthogClient = config.public.enablePosthog
		? posthog.init(config.public.posthogToken, {
				api_host: 'https://us.i.posthog.com',
				person_profiles: 'identified_only',
		  })
		: null

	return {
		provide: {
			posthog: posthogClient as PostHog,
			identifyUser: (user: IUser) => {
				const userData: any = {
					id: user._id,
					email: user.email,
					role: user.role,
					createdAt: user.createdAt,
				}

				posthogClient?.identify(user._id, userData)
			},
		},
	}
})
