<template>
	<footer class="w-full bg-[#272F13] py-10 px-4">
		<div class="lg:container lg:mx-auto lg:flex lg:justify-between">
			<div>
				<p class="text-base text-white lg:w-1/2">
					Compartilhe conosco a caixa do seu streamer favorito nas redes sociais!
				</p>
				<div class="flex items-center space-x-4 mt-4">
					<button
						class="bg-[#70704C] p-3 rounded-2xl"
						v-for="socialMedia in socialMedias"
						:key="socialMedia.color"
						@click="() => redirectTo(socialMedia.href)"
						:title="socialMedia.title"
					>
						<component
							:is="socialMedia.icon"
							class="text-white w-5 h-5"
							:style="{ color: socialMedia.color }"
						/>
					</button>
				</div>

				<div class="mt-8 lg:hidden">
					<p class="text-base font-bold text-white/60">Certificado</p>
					<div class="space-y-4 mt-4">
						<img
							v-for="(_, i) in 2"
							:src="`/images/landing-page/security-banner-${i + 1}.png`"
							class="w-[100px]"
						/>
					</div>
				</div>

				<div class="mt-8">
					<p class="text-base font-bold text-white/60">Forma de pagamento</p>
					<div class="space-x-2 flex items-center mt-4">
						<img
							v-for="item in paymentMethods"
							:src="`/images/landing-page/payment-methods/${item}.svg`"
							class="w-[35px]"
						/>
					</div>
				</div>

				<button @click="redirectTo('https://spaceshipws.com/')" title="Spaceship">
					<IconsLandingPageSpaceshipSponsor class="mt-8 lg:hidden" />
				</button>
			</div>

			<div class="hidden lg:block">
				<div class="">
					<p class="text-base font-bold text-white/60">Certificado</p>
					<div class="space-y-4 mt-4">
						<img
							v-for="(_, i) in 2"
							:src="`/images/landing-page/security-banner-${i + 1}.png`"
							class="w-[100px]"
						/>
					</div>
				</div>

				<button @click="redirectTo('https://spaceshipws.com/')" title="Spaceship">
					<IconsLandingPageSpaceshipSponsor class="mt-8" />
				</button>
			</div>
		</div>
	</footer>
</template>

<script setup lang="ts">
import IconsSocialX from '~/components/icons/social/X.vue'
import IconsSocialDiscord from '~/components/icons/social/Discord.vue'
import IconsSocialInstagram from '~/components/icons/social/Instagram.vue'

const socialMedias = [
	{
		icon: IconsSocialX,
		color: '#70704C',
		href: 'https://x.com/StreamersClubBr',
		title: 'X',
	},
	{
		icon: IconsSocialDiscord,
		color: '#5865F2',
		href: 'https://discord.gg/JS8YeMqYkZ',
		title: 'Discord',
	},
	{
		icon: IconsSocialInstagram,
		color: '#E1306C',
		href: 'https://www.instagram.com/streamersclubbr',
		title: 'Instagram',
	},
]

const paymentMethods = ['pix', 'visa', 'amex', 'mastercard', 'elo', 'hipercard', 'diners']

const redirectTo = (href: string) => {
	window.open(href, '_blank')
}
</script>
